import React from 'react';
import {
  ChakraProvider,
  Box,
  Container,
  Heading,
  Text,
  HStack,
  Icon,
  Image,
  Link,
  extendTheme,
  Flex,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import Log from './Log';
import EngineeringProjects from './EeProjects';
import CodingProjects from './CodingProjects';
import CV from './Cv';
import sky from './assets/sky.png';
import charles from './assets/beautiful_charles.png';

// Lightish theme
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "white", // Light background color
        color: "gray.700", // Dark text color for readability
        fontFamily: "Arial, sans-serif", // Ensure a clean, modern look
      },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        color: "gray.800", // Darker color for headings
        fontWeight: "bold",
      },
      sizes: {
        xl: { fontSize: "3xl" }, // Adjust large heading sizes as in the example
        lg: { fontSize: "2xl" },
        md: { fontSize: "xl" },
      },
    },
    Text: {
      baseStyle: {
        color: "gray.600", // Subtle color for descriptive text
      },
    },
    Container: {
      baseStyle: {
        maxW: "container.md",
        p: 4,
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Container maxW="container.md" py={10} centerContent>
        {/* Header */}
        <Flex justifyContent="space-between" alignItems="center" mb={10}>
          <HStack spacing={6}>
            <Link href="/" color="gray.800" fontWeight="bold">
              Home
            </Link>
            <Link href="/eeprojects" color="gray.800" fontWeight="bold">
              Engineering 
            </Link>
            <Link href="/codingprojects" color="gray.800" fontWeight="bold">
              Coding
            </Link>
            <Link href="/cv" color="gray.800" fontWeight="bold">
              CV
            </Link>
            {/*<Link href="/log" color="gray.800" fontWeight="bold">
              Log
            </Link> */}
          </HStack>
        </Flex>

          {/* Profile Section */}
          <Box mt={10} textAlign="center" shadow="md">
            {/*<HStack justifyContent="space-around">
              <Image src={sky} alt="the sky" boxSize="10rem" fit="cover" borderRadius="full" margin={3}/>
              <Image src={charles} alt="doggy" boxSize="10rem" fit="cover" borderRadius="full" margin={3} align="right"/>
            </HStack>*/}

            {/*<Heading as="h2" size="xl">Nicolas</Heading>
            <Text fontSize="md" color="gray.500" mb={4}>Electrical and Computer Engineering Major</Text>*/}
            <Text fontSize="lg" color="gray.700" px={4}>
              Hi I'm Nicolas!<br /> At the moment I am:
            </Text>
            <UnorderedList textAlign="left" fontSize="md" color="gray.700" px={6} mt={2} spacing={2}>
              <ListItem>Studying for my finals...</ListItem>
              <ListItem>An ECE research assistant</ListItem>
              <ListItem>Building a new propulsion system<br />for
                <Link href="https://www.macrocketry.ca/" color="gray.800" fontWeight="bold"isExternal> McMaster Rocketry</Link>
              </ListItem>
              <ListItem>Competitive programming<br />by hobby</ListItem>
            </UnorderedList>

            <Text fontSize="lg" color="gray.700" px={4} margin={6}>
              
            </Text>
          </Box>
        </Container>

        {/* Footer */}
        <HStack spacing={6} justify="center" mt={4}>
          <Link href="https://www.linkedin.com/in/nicolaslepki/" isExternal>
            <Icon as={FaLinkedin} boxSize={6} color="grey.200" />
          </Link>
          <Link href="https://github.com/lepkinh" isExternal>
            <Icon as={FaGithub} boxSize={6} color="grey.200" />
          </Link>
        </HStack>
        {/*<Text fontSize="2xs" color="gray.400" px={4} mt={4} textAlign="center">
          © 2024 Very Real Copyright.
        </Text>*/}
    </ChakraProvider>
  );
}

function Main() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/log" element={<Log />} />
        <Route path="/eeprojects" element={<EngineeringProjects />} />
        <Route path="/codingprojects" element={<CodingProjects />} />
        <Route path="/cv" element={<CV />} />
      </Routes>
    </Router>
  );
}

export default Main;
