import React from 'react';
import { ChakraProvider, Container, Flex, Heading, Text, VStack, HStack, Box, extendTheme, Link, Icon, Image } from '@chakra-ui/react';
import { FaLinkedin, FaGithub} from 'react-icons/fa';
import Guh from './assets/Guh.png';
import engChiwa from './assets/chihuahuaEngineer.jpg';

// Lightish theme
const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "white", // Light background color
        color: "gray.700", // Dark text color for readability
        fontFamily: "Arial, sans-serif", // Ensure a clean, modern look
      },
    },
  },
  components: {
    Heading: {
      baseStyle: {
        color: "gray.800", // Darker color for headings
        fontWeight: "bold",
      },
      sizes: {
        xl: { fontSize: "3xl" }, // Adjust large heading sizes as in the example
        lg: { fontSize: "2xl" },
        md: { fontSize: "xl" },
      },
    },
    Text: {
      baseStyle: {
        color: "gray.600", // Subtle color for descriptive text
      },
    },
    Container: {
      baseStyle: {
        maxW: "container.md",
        p: 4,
      },
    },
  },
});

function EeProjects() {
  return (
    <ChakraProvider theme={theme}>
      <Container maxW="container.md" py={10} centerContent>

        {/* Header */}
        <Flex justifyContent="space-between" alignItems="center" mb={10}>
          <HStack spacing={6}>
            <Link href="/" color="gray.800" fontWeight="bold">
              Home
            </Link>
            <Link href="/eeprojects" color="gray.800" fontWeight="bold">
              Engineering 
            </Link>
            <Link href="/codingprojects" color="gray.800" fontWeight="bold">
              Coding
            </Link>
            <Link href="/cv" color="gray.800" fontWeight="bold">
              CV
            </Link>
            {/*<Link href="/log" color="gray.800" fontWeight="bold">
              Log
            </Link> */}
          </HStack>
        </Flex>

        {/* Title */}
        <VStack spacing={1} align="start" margin={5}>
          <Heading as="h2" size="md" color="gray.800" fontWeight="bold" px={4}>Technical Projects</Heading>

          <Text fontSize="lg" color="gray.700" px={4}>
            Hardware, firmware, primarily electrical engineering projects.
          </Text>
        </VStack>

        {/* Section */}
        <VStack spacing={6} align="start" mt={10}>
          <Heading as="h2" size="md" color="gray.800" fontWeight="bold" px={4}></Heading> {/* Section title*/}

          {/* Project */}
          <Box p={5} shadow="lg" borderWidth="1px">
            <Heading as="h2" size="md" color="gray.800" fontWeight="bold" px={4}>
              Work in progress, updating website.
            </Heading>

            <Text fontSize="lg" color="gray.700" px={4} mb={4} margin={5}>
              
            </Text>
            <HStack spacing={4} justify="center">
              
            </HStack>
            <Text fontSize="lg" color="gray.700" px={4} mt={4} margin={5}>
              <Link href="" color="gray.800" fontWeight="bold" isExternal>
              </Link>
            </Text>
          </Box>
        </VStack>

        <Box p={5} shadow="md" borderWidth="1px" marginBottom={3} marginTop={8}>
            <HStack justify="center">
              <Image src={engChiwa} alt="nerd dog on laptop" width="20em" borderRadius="md"/>
            </HStack>
        </Box>

        {/* Links to Socials */}
        <HStack spacing={6} justify="center" mt={4}>
          <Link href="https://www.linkedin.com/in/nicolaslepki/" isExternal>
            <Icon as={FaLinkedin} boxSize={6} color="grey.200" />
          </Link>
          <Link href="https://github.com/lepkinh" isExternal>
            <Icon as={FaGithub} boxSize={6} color="grey.200" />
          </Link>
          {/*
          <Link href="" isExternal>
            <Icon as={FaTwitter} boxSize={8} color="teal.200" />
          </Link>
          */}
        </HStack>
        {/*<Text fontSize="2xs" color="gray.400" px={4} mt={4} textAlign="center">
          © 2024 Very Real Copyright.
        </Text>*/}
      </Container>
    </ChakraProvider>
  );
}

export default EeProjects;